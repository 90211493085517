
/* Video Post */

.video-post-right-tabs{
    width: 340px;
    height: 100vh;
    position: fixed;
    top: 64px;
    right: 0px;
    z-index: 901;
    border: 0.25px solid #70707033;
    padding: 10px 0px;
    overflow-y: auto;
    padding-bottom: 74px;
}
.video-post-right-tabs .nav-tabs .nav-link{
    padding: .25rem .5rem;
     border: none;
    border-top-left-radius: 0rem; 
     border-top-right-radius: 0rem;
     color: #646464;
     font: normal normal bold 12px/18px Muli;
}
.video-post-right-tabs .nav-tabs{
    border-bottom: 3px solid #EFFBFF;
}
.video-post-right-tabs .nav-tabs .nav-link.active{
    border-bottom: 3px solid #1694E3;
    color:#1694E3;
}
.video-middle-container{
    margin-bottom:64px;
}
.video-content-editor .header{
    font: normal normal 600 18px/26px Muli;
    letter-spacing: 0px;
    color: #707070;
}
.video-content-editor .image-preview{
    min-height: 170px;
    background-color: #f0f9ff;
    display: flex;
    align-items: center;
    width:60%;
    max-width: 340px;
    margin:auto;
    justify-content: center;
    border: 0.25px solid #95989a57;
    border-radius: 4px;
    overflow: hidden;
    height: 170px;
}
.video-content-editor .nav-tabs .nav-link{
    padding: .25rem .5rem;
     border: none;
    border-top-left-radius: 0rem; 
     border-top-right-radius: 0rem;
     color: #145976;
     font: normal normal bold 12px/18px Muli;
}
.image-preview .image-holder{
    width: 100%;
}
.image-preview .image-holder img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.video-content-editor .nav-tabs{
    border-bottom: 3px solid #EFFBFF;
}
.video-content-editor .nav-tabs .nav-link.active{
    border-bottom: 3px solid #1694E3;
    color:#145976;
}
.video-content-editor .ant-input{
    box-shadow: 0px 3px 8px #64646414;
    border: 0.25px solid #64646445;
    border-radius: 0px 4px 4px 0px;
}
.video-image-container{
    position: absolute;
    width: 100%;
    height:68px;
    bottom:0;
    left:0;
    background-color:#EFFBFF;
    border-top: 0.25px dashed #707070;
}

.video-image-container .list{
    height: 68px;
    color:#1694E3;
    font: normal normal bold 12px/18px Muli;
}
.video-image-container .item{
    height: 68px;
    padding: 0 .25rem;
}
.image-modal{
    background: #F5F8F7 0% 0% no-repeat padding-box !important;
box-shadow: 0px 0px 20px #0000001F;
border-radius: 10px !important;
}
.image-modal .close{
    padding: 0px;
    margin: 0px 0px 0px auto;
    color: #ef476f;
    opacity: 1;
}
.image-modal .close:focus, .close:active{
    outline:none !important;
    opacity: 1;
}
.music-list .title{
    font: normal normal 600 14px/20px Muli;
    letter-spacing: 0px;
    color: #646464;
    line-height: 22px;
}
.template-image{
    border-radius: .21em;
    width: 130px;
    margin: .5em;
    padding: .2em;
    box-sizing: border-box;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12);
}
.template-image.selected{
    background:#1694e3;
}
.template-image img{
    width: 100%;
}
.ThemeName {
    font-size: .8em;
    padding: .2em .3em;
    line-height: 1em;
    margin-top: .3em;
    cursor: pointer;
    text-align: left;
}
.template-image.selected .ThemeName{
    color: #fff;
}
.image-list{
    height: 68px;
    padding: 5px;
  
}
.image-item{
    position: relative;
    cursor: pointer;
    
    display: flex!important;
}
.image-list .item{
    border: 0.25px solid #707070;
    border-radius: 4px;
    width:75px;
    height: auto;
    object-fit: cover;
    margin:0 5px;
    padding:0px;
}
.image-item .clip-no{
    display:none;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left: 0;
    text-align: center;
    padding-top: 28%;
    color:#191919;
    font-size: 14px;
    z-index: 9;

}
.image-item:hover .clip-no{
    display:block;
}
.image-item:hover .item{
    opacity: 0.4;
}
.playimageicon{
    display:none;
    position: absolute;
    left:47%;
    top:47%
}
.image-holder:hover .playimageicon{
    display:block;
}
.video-bottomtools{
    bottom:0;
    height: 54px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.25px solid #70707021;
    opacity: 1;
    width:100%;
    z-index: 999;
    padding-right: 370px !important;
}