@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}
body > iframe[style*='2147483647']{
  display: none;
}
.w-full {
  width: 100% !important;
}
.max-w-full {
  max-width: 100% !important;
}
.home-bg {
  background-color: #000a31 !important;
}
.bg-hg,
.ant-layout {
  background-color: #f5f5f5 !important;
}
.home-circle-1 {
  top: -180px;
  left: -265px;
  width: 400px;
  height: 400px;
  background: transparent linear-gradient(180deg, #f8f8ff 0%, #f8f8ff00 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-radius: 200px;
  z-index: 11;
}
.home-circle-2 {
  top: -325px;
  left: 30px;
  width: 400px;
  height: 400px;
  background: transparent linear-gradient(180deg, #f8f8ff 0%, #f8f8ff00 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-radius: 200px;
  z-index: 11;
}
.bg-blue-1 {
  background-color: #0079ff !important;
}
.bg-red-1 {
  background-color: #f6327d !important;
}
.text-h {
  color: #001940 !important;
}
.text-hr {
  color: #f6327d !important;
}
.text-hb {
  color: #1779fa !important;
}
.text-ht {
  color: #071b2a !important;
}
.text-hlt {
  color: #a0a0a0 !important;
}
.space-hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #dedede !important;
}
.head-notificationsBox {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background: #f6327d;
  display: inline-block;
  vertical-align: middle;
}

/* sidebar menu */
.text-hl {
  color: #a0a0a0 !important;
}
.ant-menu-item {
  border-color: white !important;
  font-weight: 600;
}
.ant-menu-item-selected {
  color: #f6327d !important;
  background: transparent !important;
  font-weight: 600;
  border-color: #f6327d !important;
}
.ant-menu-item-selected .text-hl {
  color: #f6327d !important;
  background: transparent !important;
  font-weight: 600;
  border-color: #f6327d !important;
}
.search-left-menu .ant-menu-item-selected {
  
  background: rgb(240, 242, 245) !important;
  font-weight: 600;
  border-color: transparent !important;
  border-radius: 10px;
}
.search-left-menu .text-hl {
  color: #333333 !important;
}
.feed-visual-56 {
  padding-bottom: 46.25% !important;
}
.social-connection {
  width: 150px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 11px;
  border: solid 1px rgba(202, 205, 211, 0.5);
  border-width: 2px;
  cursor: pointer;
}
.social-connection.selected {
  height: 50px;
}

.social-connection.connect:hover {
  border-color: #1694e3 !important;
}
.social-connection.selected:hover {
  border: solid 1px rgba(202, 205, 211, 0.5);
}
.social-connection.selected img {
  border-radius: 0.25rem !important;
}
.social-connection.box-shadow {
  box-shadow: -8px 8px 0 -2px rgba(234, 235, 237, 0.5);
}
.social-connection.active {
  border-color: #1694e3 !important;
}
.social-connection .twitter-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadContainer .ant-upload-select,
.uploadContainer.colorpicker {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.uploadContainer .activeImage {
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.colorpicker-row {
  overflow: hidden;
  position: relative;
}
.colorpicker-row .colorpicker-container {
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  border-radius: 5px 0px 0px 5px;
  width: 60px;
  height: 100%;
}
.colorpicker-container input {
  position: absolute;
  height: 100px;
  width: 90px;
  top: -11px;
  left: -11px;
  cursor: pointer;
}
.colorpicker .text {
  font-weight: bold;
  font-size: 18px;
}
.layout .card-body {
  padding: 0 !important;
  overflow: hidden;
  height: 181.5px;
}
.layout .brand-template {
  position: relative;
  overflow: hidden;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 700px;
  height: 350px;
  user-select: none;
}
.brand-template .background {
  position: absolute;
  user-select: none;
  cursor: pointer;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
.signup .template-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  border: 0.25px solid #7070702f;
  border-radius: 10px;
}
.signup .template-container.active {
  border: 2px solid #673ab7c7;
}

.branding-render {
  min-height: 1px;
  width: 100%;
}
.template-layout {
  position: relative;
  overflow: hidden;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 700px;
  height: 350px;
  user-select: none;
}
.template-layout .tl-item {
  position: absolute;
  user-select: none;
  cursor: pointer;
}
.template-layout .tl-background {
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
.template-layout .tl-container {
  width: 100%;
  height: 100%;
  z-index: 2;
}
.tl-svg {
  position: absolute;
  user-select: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tl-svg svg {
  fill: inherit !important;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.tl-svg svg * {
  fill: inherit !important;
}
.template-layout .tl-profile-image {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-size: cover;
  z-index: 4;
}
tl-profile-image.btl-profile-image-circle {
  border-radius: 48px;
}
.template-layout .tl-author,
.template-layout .tl-brand {
  white-space: nowrap;
  z-index: 5;
}
.template-layout .tl-quote {
  position: relative;
  z-index: 3;
}
.template-layout .tl-quote .text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  white-space: pre-line;
}
.ant-btn-Div {
  padding: 0 12px !important;
  background-color: #f6327d !important;
  color: #fff !important;
  border: none !important;
}
.ant-btn-Div.ant-btn-icon-only {
  background: #e21966 !important;
  align-items: center;
  display: flex;
}
.social-profile .ant-dropdown-menu-title-content {
  display: flex;
  flex-direction: row;
}

.ct-image .ant-upload-select {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.blurVisualImage{
  height: 130% !important;
  width: 130% !important;
}
.tags-added{
  background: #F1F5F8 0% 0% no-repeat padding-box;
  border: 0.25px solid #707070;
  border-radius: 8px;
  opacity: 1; 
  padding: 3px 10px;
  /* text-transform: capitalize; */
  font: normal normal 600 14px/20px Muli;
  letter-spacing: 0px;
  color: #0C273C;
  line-height: 27px;
  min-height: 28px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 27px;
  padding-bottom: 0px;
}
.tags-added.mention{
  text-transform: lowercase;
}
.tags-added .ant-tag-close-icon{
  margin-left: 6px;
  margin-top: -1px;
}
.tag-input {
  border:none !important;
  max-width: 250px;
}
.tag-input:focus {
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 12%);
   box-shadow: 0 0 0 2px rgb(24 144 255 / 12%);
}
.ant-notification{
  z-index: 10101 !important;
}
.action-profile{
  width: 32px;
  height: 32px;
}
.box-radius-large{
  border-radius: 16px;
}
.displya-wall{
  display: flex;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin: 0 0 0 20px;
}
.my-masonry-grid .poke-item{
  float: left;
  margin-bottom: 20px;
}
.my-masonry-grid_column {
  
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  
  margin-bottom: 0px;
}
.poke-view {
  width:94%;
  margin: auto;
  max-width: 320px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}
.authorDetails .postAuthorName {
  font-size: 13px;
  white-space: nowrap;
  text-decoration: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.postedTxt {
  width: 100%;
  display: block;
  word-wrap: break-word;
  line-height: 140%;
  margin: 10px 0;
  overflow-wrap: break-word;
  /* position: absolute; */
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
  position: static;
    transform: none;
    -moz-transform: none;
    -webkit-transform: none;

}
.postContent p {
  padding: 0 10px;
}
.postContentCard h4 {
  display: table;
  width: 100%;
}
.trimcont, .truncated {
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: auto;
}
.trimcont {
  -webkit-line-clamp: 7;
  white-space: break-spaces;
}
.postContent div.image {
  position: relative;
}
.blur-bg, .blur-bg:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
    background-size: cover;
    background-position: 50% 50%;
}

.list-ul{
  list-style: square !important;
    list-style-position: outside !important;

}
.myspace .ant-space-item{
  width: 50% !important;
}

.h1, h1 {
  font-size: 2.5rem !important;
}
.h2, h2 {
  font-size: 2rem;
}
.h3, h3 {
  font-size: 1.75rem;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0 !important;
  margin-bottom: .5em !important;
  color: rgba(0,0,0,.85);
  font-weight: 500 !important;
}
.shortener-block {
  width: 180px;
  float: left;
  margin-right: 1rem;
  cursor: pointer;
}
.shortener-container {
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  border: 0.25px solid #707070;
  border-radius: 6px;
  opacity: 1;
  width: 180px;
  height: 80px;
  float: left;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shortener-block span {
  padding: 0.5rem;
  float: left;
  text-align: left;
  font: normal normal 600 14px/20px Muli;
  letter-spacing: 0;
  color: #646464;
  display: flex;
}

/* Profle Blocks */

.social-profile-container{
  background: #F6F6F6 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.25px solid #70707054;
  border-radius: 10px;
  width: 320px;
  height: 132px;  
  margin-right: 1rem;    
  margin-bottom: 2rem; 
  padding: 0.5rem;
}
.social-profile-container.white{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001f;
  overflow: hidden;
  border: 0.25px solid #7070702e;
}
.social-profile-container .footer{
  background: #F5F8F7;
}
.abs-right-5 {
  right: 5px!important;
}
.content-templates .ant-list-item-action li{
  display: flex !important;
}
.time-wrapper{    
  position: relative;
  margin-bottom: 30px;
  min-width: 150px;
}
.time-wrapper.add-time{
  min-width: 70px;
  max-width: 20px !important;
}
.time-wrapper .in{
  background: #f1f1f3;
  border: 0;
  color: #2f3c4d!important;    
  padding: 14px 10px 14px 10px;
  border-radius: 9px;
  font-size: 18px;
  line-height: 29px;
  position: relative;
  cursor: pointer;
}
.time-wrapper .in.input span {flex:1}
.time-wrapper .bt-icon{
  cursor: pointer;
}
.time-wrapper .clock{
  display: block;
}
.time-wrapper .bin{
  display: none;
}
.time-wrapper:hover .clock{
  display:none;
}
.time-wrapper:hover .bin{
  display:block;
}
.time-wrapper .ant-picker{
  background: #f1f1f3;
  border: 0;   
  line-height: 29px;
  position: relative;
  cursor: pointer;
}
.time-wrapper .ant-picker-input input {
  color: #2f3c4d!important;    
  font-size: 18px;
  cursor: pointer;
}
.time-wrapper .ant-picker-focused{
  box-shadow: none !important;
}
.ScheduleTemplatesBox {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border: 0.25px solid hsla(0,0%,43.9%,.2196078431372549);
  border-radius: 4px;
}
.settings .template-container,.template-container {
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  border: 0.25px solid hsla(0,0%,43.9%,.1843137254901961);
  border-radius: 10px;
}
.template-actions {
  width: 100%;
  background: #f5f8f7 0 0 no-repeat padding-box;
  border-radius: 0 0 10px 10px;
  opacity: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}
.template-actions .template-info {
  font-size: 12px;
  color: #707070;
  width: 75%;
}
.template-actions .template-edit {
  display: flex;
  justify-content: space-between;
}
.template-edit .icons {
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #eaebed;
  margin: 0 1px;
}

.about-texts li{
  list-style: auto !important;
}
.about-texts ol,.about-texts ul{
  padding-left: 40px;
}
.ant-message{
  z-index: 10000000 !important;
}
.blur-mdx{
  filter: blur(8px) !important;
    -webkit-filter: blur(8px) !important;
}
.portrait-background{
    max-height: 320px;
}
.thumbe-background{
  max-height: 120px !important;
}
p {
  margin-top: 0 !important;
  margin-bottom: 1em !important;
}
.h5, h5 {
  font-size: 1.25rem !important;
}
.custommsg-class{
  display: flex;
  /* align-items: end; */
  justify-content: end;
  margin-right: 10px;
}
.swipe {
  position: absolute;
}
.cardContainer {
  width: 90vw;
  max-width: 350px;
  height: 580px;
}
.tinder-container{
  width: 350px;
 
}
.tindercard {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 350px;
  height: 580px;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10);
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}



.tindercard h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.tindercard .infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}
.ant-btn.shopify_btn {
  background-color: #86c128;
  color: #fff;
  
}
.z-20{
  z-index: 20 !important;
}
.custom-ant-r .ant-radio-button-wrapper:not(:first-child)::before{
  display: none !important;
}
.custom-ant-r .ant-radio-button-wrapper {
  background-color: rgb(255, 255, 255) !important;
  border: solid thin #ccc !important;
  color: rgba(109, 40, 217, 1) !important;

}
.custom-ant-r .ant-radio-button-wrapper-checked{
  background-color: rgba(221, 214, 254, 1) !important;
  border: none !important;
}