.modalAuth .ant-modal-content{
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 12px #191F3514;
    border-radius: 16px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    position: relative;
}
.modalAuth .ant-form-item-label > label {
    color: white !important;
    font-size: small !important ;
}
.modalAuth .ant-divider-horizontal.ant-divider-with-text{
    border-top-color:white;
}