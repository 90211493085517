/* SlideContentCss FIle */
@import url("https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Css/fdff0bac40316c9c0208cd9d96716f03.css");
@import url("https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Css/c8f63e4dbe09b0e116309115f02223f0.css");

@font-face {
    font-family: 'Teko SemiBold';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Teko-SemiBold.ttf');
}
@font-face {
    font-family: 'Raleway';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Raleway-Regular.ttf');
}
@font-face {
    font-family: 'Baloo';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Baloo-Regular.ttf');
}
@font-face {
    font-family: 'Khula';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Khula-Bold.ttf');
}
@font-face {
    font-family: 'Bitter, Bold';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Bitter-Bold.ttf');
}
@font-face {
    font-family: 'Bitter';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Bitter-Regular.ttf');
}
@font-face {
    font-family: 'Oswald, Bold';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/Oswald-Bold.ttf');
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/helveticaneue_pwc.ttf');
}
@font-face {
    font-family: 'Noto Sans Devanagari';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/NotoSansDevanagari-Bold.ttf');
}
@font-face {
    font-family: 'Noto Sans Bengali';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/NotoSansBengali-Bold.ttf');
}
@font-face {
    font-family: 'Noto Sans Telugu';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/NotoSansTelugu-Bold.ttf');
}
@font-face {
    font-family: 'Noto Sans Devanagari';
    src: url('https://blue-container.s3.eu-west-2.amazonaws.com/app-static/fonts/NotoSansDevanagari-Bold.ttf');
}


.StagePreviewHolder {
    display: flex;
    margin: 20px auto;
    background: rgba(204, 204, 204, 0.2);
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 3px;
    max-width: 340px;
}
.StagePreviewHolder.preview{
    max-width: 576px;
}
.StagePreviewHolder .Silde-Preview {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 0;
}

.Silde-Preview.Rectangle {
    padding-bottom: 56.25%;
}
.StagePreviewHolder.Rectangle > .Silde-Preview {
    width: 340px;
}
.StagePreviewHolder.preview > .Silde-Preview {
    width: 576px;
}
.VisualBox {
    width: 100%;
    position: absolute;
    height: 100%;
}
.HeroImageBox {
    height: 100%;
    padding: 0px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 3rem;
}
.HeroImageBox.NoPadding {
    padding: 0;
}
.Silde-Preview .BluredImage {
    width: calc(100% + 15px);
    max-width: calc(100% + 15px);
    overflow: hidden;
    -webkit-filter: blur(8px) brightness(50%);
    -moz-filter: blur(8px) brightness(50%);
    -o-filter: blur(8px) brightness(50%);
    -ms-filter: blur(8px) brightness(50%);
    filter: blur(6px) brightness(39%);
    background-size: cover;
    margin-top: -5px;
    margin-left: -5px;
    height: inherit;
    background-position: center;
    height: 115%;
}
.Silde-Preview .SlideVisuals {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.SlideVisuals.imageType {
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center,top;
    background-size: contain;
}
.Silde-Preview .SlideContent{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: top, 50%;
    background-size: contain;
    z-index: 10;
    background: #fff;
}
.SlideContent-Container {
    width: 100%;
    height: 100%;
}

.Preview-Canvas{
    width:100%;
    height: 100%;
    background: transparent;
}
.fakeVideoPlayer{
    display: none;
}
#fakeVideoPlayer{
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
}
.FakeFootageContainer{
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    overflow: hidden;
}
#fakeVideoPlayerBlurred{
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: -2;
    -webkit-filter: blur(8px) brightness(50%);
    -moz-filter: blur(8px) brightness(50%);
    -o-filter: blur(8px) brightness(50%);
    -ms-filter: blur(8px) brightness(50%);
    filter: blur(6px) brightness(39%);
}