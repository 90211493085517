.card-content .list-container .list-card {
    padding: 10px;
    display: block;
    position: relative;
    margin-left: 12px;
    background: #fff;
    margin-bottom: 10px;
    margin-right: -8px;
    box-shadow: 0px 3px 6px #0000001A;
    border-radius: 6px;
    cursor: pointer;
}
.list-card:hover{
    background: #fff !important;
}
.card-content.blank {
    padding: 15px;
}
.campaign-cards {
    width: 100%;
}
.list-profile{
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}
.list-profile .play-btn{
    position: absolute;
    width:42px;
    height:42px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #cccccc9c
}
.list-profile .campaign-image{
    width:42px;
    height:42px;
    border-radius: 4px;
   
}
.list-profile .campaign-title{
    text-align: left;
    font-size: 14px;
    font-weight:700;
    line-height: 22px;
    color: #0C273C;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.campaign-title.text-full{
    overflow: inherit;
    text-overflow: unset;
    -webkit-line-clamp: unset;
    display: block;
    -webkit-box-orient: vertical;
}
.list-analytics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.campaign-timestamp, .campaign-clicks{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:12px;
    font-weight:600;
    line-height:18px;
}
.list-progress {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size:12px;
    font-weight:600;
    line-height:18px;
}
.list-actions{
    position: absolute;
    top: 5px;
    right: 5px;
}
.campaign-timeline {
    display: flex;
    position: relative;
}
.campaign-timeline .track{
    background-color: #EFFBFF;
    height: 8px;
    width: 100%;
    padding: 0 18px;
    position: relative;
}
.track .flag-container{
    position: absolute;
    top: -10px;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    justify-content:space-evenly;
    padding: 0 13px;
}
.campaign-timeline .starttime{
    width:12px;
    height: 12px;
    background-color: #1694E3;
    top:-2px;
    border-radius: 50%;
    position: absolute;
    z-index: 10;
}
.campaign-timeline .endtime{
    width:12px;
    height: 12px;
    background-color: #30B67C;
    top:-2px;
    border-radius: 50%;
    position: absolute;
    z-index: 10;
    right:0;
}
.addnewbtn {
    width: 184px;
    height: 40px;
    background: #1694E3 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: left;
}

.searchcontent {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001A;
    
    border-radius: 6px;
    width: 340px !important;
    align-items: center;
    display: flex;
    border: none;
    height: auto;
}

.card-header-text {
    height: 23px;
    text-align: left;
    letter-spacing: 0px;
    color: #0C273C;
    opacity: 1;
}

.card-content {
    height: 400px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 4px;
    opacity: 1;
    flex: 0 0 27rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 216px);
    overflow: hidden;
}

.list-container::-webkit-scrollbar,
.sidebar-secondary::-webkit-scrollbar,.video-post-right-tabs::-webkit-scrollbar,.custom-scroll::-webkit-scrollbar{
    width: 4px;
    height: 4px;
}

.list-container::-webkit-scrollbar-thumb,
.sidebar-secondary::-webkit-scrollbar-thumb,.video-post-right-tabs::-webkit-scrollbar-thumb,.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #cacdd3;
}

.list-container::-webkit-scrollbar-track,
.sidebar-secondary::-webkit-scrollbar-track,.video-post-right-tabs::-webkit-scrollbar-track,.custom-scroll::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #f9f9f9;
}

.card-content .list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    overflow-y: auto;
    background: #f9f9f9;
    border-top: solid 15px #f9f9f9;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding-right: 17px;
    border-right: solid 6px #f9f9f9;
    margin-top: 1px;
}

.modal-content.campaign-new-modal {
    background: #F2F3F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000001F;
    border-radius: 10px;
    padding: 10px 15px;
}

.modal-content.campaign-new-modal .modal-header,
.modal-content.campaign-new-modal .modal-body,
.modal-content.campaign-new-modal .modal-footer {
    padding: 0;
    border: none;
}
.ant-modal.campaign-new-modal .ant-modal-content{
    background: #F2F3F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000001F;
    border-radius: 10px;
    padding: 10px 15px;
}
.ant-modal.campaign-new-modal .ant-modal-content .ant-modal-header, .ant-modal.campaign-new-modal .ant-modal-content .ant-modal-body{
    
    background:none;
    padding: 10px 10px;
    
}
.ant-modal.campaign-new-modal .ant-modal-content .ant-modal-header .ant-modal-title{
    color: #0C273C;
    font-weight: bold;
}
.ant-modal.campaign-new-modal .ant-modal-close {
    position: absolute;
    top: 1px;
    right: 2px;
}
.ant-modal.campaign-new-modal .ant-modal-close-x{
    display: block;
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-style: normal;
    line-height: 40px;
}
span.modal-title {
    font-weight: 700;
    color: #0C273C;
    font-size: 14px;
    line-height: 18px;
}

.campaign-new-info {
    letter-spacing: 0px;
    color: #646464;
}

.campaign-new-info .ant-checkbox-wrapper {
    color: #0C273C;
}

.drip-selection .ant-radio-inner {
    border-radius: 0;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.drip-selection .ant-radio-checked .ant-radio-inner {
    background-color: #1890ff;
    border-color: #1890ff;
}

.drip-selection .ant-radio-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: '';
}

.drip-selection .ant-radio-checked .ant-radio-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
    top: 50%;
    left: 22%;
    width: 5.71428571px;
    height: 9.14285714px;

}

.link-failed .ant-input,
.link-failed .ant-input:hover {
    border-color: red;
}

.sidebar-secondary {
    width: 260px;
    height: 100vh;
    position: fixed;
    top: 64px;
    left: 0px;
    z-index: 900;
    background-color: #F6F6F6;
    border: 0.25px solid #70707033;
    padding: 30px 21px;
}

.sidebar-secondary.with-scroll {
    overflow-y: auto;
    padding-bottom: 100px;
}

.main-content-review {
    padding-left: 260px !important;
    padding-top: 30px;
}

.main-content-review.video {
    padding-left: 0px !important;
    padding-top: 30px;
}
.main-content-review.video{
    padding-top:0px;
    padding-right: 340px;
}

.sidebar-secondary.settings {
    width: 200px;

}

.sidebar-secondary a,
.sidebar-secondary a:hover {
    color: rgba(0, 0, 0, 0.85);
}

.settings .setting-menu {
    font-weight: 600;
}

.settings .setting-menu:hover {
    background-color: #fff;
}

.setting-menu.active {
    background-color: #fff;
    border-right: 2px solid #1694E3;
}

.settings .setting-menu.danger {
    color: #EF476F;
}

.settings .brand-menu {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 8px;
    text-align: left;
    font: normal normal normal 14px/20px Muli;
    letter-spacing: 0px;
    color: #646464;

}

.settings .brand-menu.active {
    color: #1694E3;
    font: normal normal bold 14px/20px Muli;
    letter-spacing: 0px;
    border: 1px solid #1694E3;
    border-radius: 4px;
    opacity: 1;
}
.settings .template-container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000001A;
    border: 0.25px solid #7070702f;
    border-radius: 10px;
}
.branding-render{    
    min-height: 1px;
    width:100%;
}
.template-layout{
    position: relative;
    overflow: hidden;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    width: 700px;
    height: 350px;
    user-select: none;
}
.template-layout .tl-item{
    position: absolute;
    user-select: none;
    cursor: pointer;
}
.template-layout .tl-background{
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}
.template-layout .tl-container{
    width: 100%;
    height: 100%;
    z-index: 2;
}
.tl-svg{
    position: absolute;
    user-select: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.tl-svg svg{
    fill: inherit!important;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
.tl-svg svg *{
    fill: inherit!important;
}
.template-layout .tl-profile-image {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-size: cover;
    z-index: 4;
}
tl-profile-image.btl-profile-image-circle {
    border-radius: 48px;
}
.template-layout .tl-author, .template-layout .tl-brand{
    white-space: nowrap;
    z-index: 5;
}
.template-layout .tl-quote {
    position: relative;
    z-index: 3;
}
.template-layout .tl-quote .text{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    white-space: pre-line;
}
.main-content-review.settings {
    padding-left: 230px !important;

}

.main-content-review.setting .ant-input,
.campaign-new-info .ant-input {
    color: #646464;
    background: #F6F6F6;
    font-size: 14px;
    border: none;
    padding: 16px 22px 14px 22px !important;
    border-radius: 9px;
}
.campaign-new-infos .ant-input-rss{
    color: #646464;
    background: #F6F6F6;
    font-size: 14px;
    border: none;
    /* padding: 16px 22px 14px 22px !important; */
    border-radius: 9px;
    overflow: hidden;
}
.noRightBorder{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.noLeftBorder{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
@media (max-width: 767.98px) {
    .main-content-review {
        padding-left: 15px !important;
        padding-top: 15px !important;
    }

    .main-content-review.settings {
        padding-left: 15px !important;
        padding-top: 15px !important;
    }
}


.accordion>.card{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.25px solid #707070;

}
.accordion>.card:not(:last-of-type) {
    /* border-bottom: 0; */
    /* border-bottom-right-radius: 0; */
    /* border-bottom-left-radius: 0; */
    border: 1px solid rgba(0,0,0,.125) !important;
    border-radius: .25rem !important;
}
.accordion>.card:not(:first-of-type) {
    border: 1px solid rgba(0,0,0,.125) !important;
    border-radius: .25rem !important;
}
.accordion>.card>.card-header{
    background-color: #FFFFFF;
    min-height: 55px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.card-header .selected-counts{
    width:120px;
    height: 30px;
    background: #F1F5F8 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding:3px 10px;
    opacity: 1;
    color: #0F589B;
    text-align: left;
    font: normal normal 600 14px/20px Muli;
    letter-spacing: 0px;
    color: #0F589B;
    text-transform: lowercase;
    line-height: 27px;
}
.tags-added{
    background: #F1F5F8 0% 0% no-repeat padding-box;
    border: 0.25px solid #707070;
    border-radius: 8px;
    opacity: 1; 
    padding: 3px 10px;
    text-transform: capitalize;
    font: normal normal 600 14px/20px Muli;
    letter-spacing: 0px;
    color: #0C273C;
    line-height: 27px;
    min-height: 28px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    line-height: 27px;
    padding-bottom: 0px;
}
.tags-added.mention{
    text-transform: lowercase;
}
.tags-added .ant-tag-close-icon{
    margin-left: 6px;
    margin-top: -1px;
}
.tag-input {
    border:none !important;
    max-width: 250px;
}
.tag-input:focus {
    -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 12%);
     box-shadow: 0 0 0 2px rgb(24 144 255 / 12%);
}
.images-gallery-listings{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-auto-rows: 150px;
    grid-gap: 20px;
}
.images-gallery-listings .item{
    padding: 0;
    border-radius: 6px;
    position: relative;
}
.images-gallery-listings .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 6px;
}

.images-gallery-listings .item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
}
.images-gallery{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-auto-rows: 150px;
    grid-gap: 20px;
}
.images-gallery .item{
    padding: 0;
    border-radius: 6px;
    position: relative;
}
.images-gallery .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 6px;
}

.images-gallery .item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}
.images-gallery.postnew .item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}
.images-gallery .item .image-action{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(44,167,240,.08);
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.image-action .abtn{
    width: 25px;
    height: 25px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0C273C;
    border: 0.25px solid #707070;
    border-radius: 2px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 12px;
}

.upload-imagebt{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.25px solid #707070;
    border-radius: 4px;
    opacity: 1;
    padding: 5px 10px;
    display:flex;
    align-items:center;
    letter-spacing: 0px;
    color: #707070;
}
.upload-imagebt .ant-spin-spinning{
    line-height:1
}
.post-quote{
    padding:10px 10px;
    display:flex;
    align-items:center;
    width: 100%;
    margin: 10 0px;
    cursor: pointer;
}
.post-quote .only-text{
    font-size:16px;
    font-style: italic;
    font-weight: 600;
    color:#707070;
    opacity: 0.6;
}
.post-quote span.selected .only-text{
    opacity: 1;
}

/* Sending to Details */
.profile-list{
    position: relative;
    margin: 0 13px;
    max-width: 47px;
    max-height: 47px;
}
.profile-list.review{
    margin: 0 7px;
    margin-bottom: 15px;
    cursor: pointer;
    transform: scale(.8);
}
.profile-list.review .AddRemoveAction{
    position: absolute;
    top:0;left: 0;
    height: 100%;width: 100%;
    display:none;
    background-color:rgba(255, 255, 255, 0.9);
}
.profile-list.review .AddRemoveAction.shown{
    display:flex;
    align-items: center;
    justify-content:center;
}
.profile-list.review .AddRemoveAction.shown .show{
    display:none;
}
.profile-list.review:hover .AddRemoveAction.shown .show{
    display:block;
}
.profile-list.review:hover .AddRemoveAction.shown .hide{
    display:none;
}
.profile-list.review:hover .AddRemoveAction{
    display: flex;
    align-items: center;
    justify-content:center;
   
}
.profile-list .source{
    position: absolute;
    position: absolute;
    right: -7px;
    bottom: -7px;
    
}
.profile-list .source.facebook{
    background: white;
    border-radius: 50%;
    
}
.profile-list .source.twitter{
    background: white;
    border-radius: 50%;
    overflow: hidden;
    padding: 2px;
}
.profile-list .source.instagram{
    background: #8a3ab9;
    border-radius: 5px;
    overflow: hidden;
    padding: 2px;
}
.profile-list .source.linkedin{
    background: white;
    border-radius: 50%;
    overflow: hidden;
    
}
.profile-list .image{
    height: 47px;
    width: 47px;
    border: 0.25px solid #707070;
    border-radius: 4px;
}
.profile-list .image img{
    width:100%;
    height:100%;
    object-fit:cover;
}

/* Posts Review */
.slick-prev:before, .slick-next:before{
    color:#0F589B !important;
}
/* .slick-slide{
  
} */
.post-card{    
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000001A;
    border: 0.25px solid #70707029;;
    border-radius: 10px;
    opacity: 1;
    width:98%;
    min-height:400px;
    margin: 5px;
}
.border-spacer{
    height: 1px;
    background-color:#70707080;
}
.post-card .header-title{
    font-weight: 700;
    font-size:14px;
    color: #646464;
}
.post-card .action-tab{
    width:60px;

}
.action-tab span{
    cursor: pointer;
}
.post-card .post-text{
    text-align: left;
    font: normal normal normal 14px/24px Muli;
    letter-spacing: 0px;
    color: #646464;
    opacity: 1;
}
.post-card .post-tags{
    font: normal normal normal 14px/18px Muli;
    letter-spacing: 0px;
    color: #707070;
    text-transform: lowercase;
    opacity: 0.8;
}
.post-card .post-link{
    font: normal normal normal 14px/18px Muli;
    letter-spacing: 0px;
    color: #707070;   
    opacity: 0.8;
}
.post-link span{
    font-style: italic;
}
.post-card .no-post{
    padding: 5px;
    font-weight: 600;
    margin: 1rem auto;
    text-align: center;
    font-size: 13px;
    background-color: #ffcdd2;
    width: 95%;
    border-radius: 5px;

}
.post-card .image-container{
    min-height: 224px;
    overflow: hidden;
}
.post-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.post-card .branding-template-layout {
    position: relative;
    overflow: hidden;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    width: 700px;
    height: 350px;
    user-select: none;
    transform: scale(.4514);
}
.btl-background{
    position: absolute;
    user-select: none;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    background-color: rgb(255, 255, 255);
    opacity: 1;
}
.bubble-container{
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -66px;
    width: 704px;
    height: 636px;
    top: -191px;

}
.bubble {
    position: absolute;
    user-select: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    fill: rgb(177, 0, 124);
    opacity: 1;
}
.bubble svg * {
    fill: inherit!important;
}
.btl-item {
    position: absolute;
    user-select: none;
    cursor: pointer;
}
.btl-profile-image {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-size: cover;
    z-index: 4;
    background-image: url(https://s3-external-1.amazonaws.com/missinglettr-media/uploads/images/social-accounts/28053-facebook/uploads/images/social-accounts/28053-facebook/34153_137104142968414_4973448_n-bsa93397.jpg);
    top: 263px;
    left: 62px;
    height: 66px;
    width: 66px;
    /* z-index: 22; */
    /* background: aquamarine; */
    background-size: cover;
    z-index: 4;
    border-radius: 48px;
}
.btl-author{
    color: rgb(0, 0, 0);
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-align: left;
    top: 286px;
    left: 138px;
    transform: translate(0px, -50%);
    white-space: nowrap;
    z-index: 5;
}
.btl-brand{
    color: rgb(120, 120, 120);
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-align: left;
    top: 310px;
    left: 138px;
    transform: translate(0px, -50%);
    white-space: nowrap;
    z-index: 5;
}
.btl-quote{
    color: rgb(255, 255, 255);
    
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    top: 16px;
    left: 50px;
    width: 571px;
    height: 192px;
    position: relative;
    z-index: 3;
}
.btl-text{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    text-align: left;
    font-size: 29px;
    
}
.launch-btn{
    background-color: #30B67C !important;
    border:none !important;
    border-radius: 4px;
}
/* Posts Review */

/* Post View */
.post-view .ant-list{
    box-shadow: 5px 5px 0 0px rgb(223 226 234 / 50%);
    border-radius: 9px;
    border: 1px solid #d9d9d98c;
}

/* Video Post */

.video-post-right-tabs{
    width: 340px;
    height: 100vh;
    position: fixed;
    top: 64px;
    right: 0px;
    z-index: 901;
    border: 0.25px solid #70707033;
    padding: 10px 0px;
    overflow-y: auto;
    padding-bottom: 74px;
}
.video-post-right-tabs .nav-tabs .nav-link{
    padding: .25rem .5rem;
     border: none;
    border-top-left-radius: 0rem; 
     border-top-right-radius: 0rem;
     color: #646464;
     font: normal normal bold 12px/18px Muli;
}
.video-post-right-tabs .nav-tabs{
    border-bottom: 3px solid #EFFBFF;
}
.video-post-right-tabs .nav-tabs .nav-link.active{
    border-bottom: 3px solid #1694E3;
    color:#1694E3;
}
.video-middle-container{
    margin-bottom:64px;
}
.video-content-editor .header{
    font: normal normal 600 18px/26px Muli;
    letter-spacing: 0px;
    color: #707070;
}
.video-content-editor .image-preview{
    min-height: 170px;
    background-color: #f0f9ff;
    display: flex;
    align-items: center;
    width:60%;
    max-width: 340px;
    margin:auto;
    justify-content: center;
    border: 0.25px solid #95989a57;
    border-radius: 4px;
    overflow: hidden;
    height: 170px;
}
.video-content-editor .nav-tabs .nav-link{
    padding: .25rem .5rem;
     border: none;
    border-top-left-radius: 0rem; 
     border-top-right-radius: 0rem;
     color: #145976;
     font: normal normal bold 12px/18px Muli;
}
.image-preview .image-holder{
    width: 100%;
}
.image-preview .image-holder img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.video-content-editor .nav-tabs{
    border-bottom: 3px solid #EFFBFF;
}
.video-content-editor .nav-tabs .nav-link.active{
    border-bottom: 3px solid #1694E3;
    color:#145976;
}
.video-content-editor .ant-input{
    box-shadow: 0px 3px 8px #64646414;
    border: 0.25px solid #64646445;
    border-radius: 0px 4px 4px 0px;
}
.video-image-container{
    position: absolute;
    width: 100%;
    height:68px;
    bottom:0;
    left:0;
    background-color:#EFFBFF;
    border-top: 0.25px dashed #707070;
}

.video-image-container .list{
    height: 68px;
    color:#1694E3;
    font: normal normal bold 12px/18px Muli;
}
.video-image-container .item{
    height: 68px;
    padding: 0 .25rem;
}
.image-modal{
    background: #F5F8F7 0% 0% no-repeat padding-box !important;
box-shadow: 0px 0px 20px #0000001F;
border-radius: 10px !important;
}
.image-modal .close{
    padding: 0px;
    margin: 0px 0px 0px auto;
    color: #ef476f;
    opacity: 1;
}
.image-modal .close:focus, .close:active{
    outline:none !important;
    opacity: 1;
}
.music-list .title{
    font: normal normal 600 14px/20px Muli;
    letter-spacing: 0px;
    color: #646464;
    line-height: 22px;
}
.template-image{
    border-radius: .21em;
    width: 130px;
    margin: .5em;
    padding: .2em;
    box-sizing: border-box;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12);
}
.template-image.selected{
    background:#1694e3;
}
.template-image img{
    width: 100%;
}
.ThemeName {
    font-size: .8em;
    padding: .2em .3em;
    line-height: 1em;
    margin-top: .3em;
    cursor: pointer;
    text-align: left;
}
.template-image.selected .ThemeName{
    color: #fff;
}
.image-list{
    height: 68px;
    padding: 5px;
  
}
.image-item{
    position: relative;
    cursor: pointer;
    
    display: flex!important;
}
.image-list .item{
    border: 0.25px solid #707070;
    border-radius: 4px;
    width:75px;
    height: auto;
    object-fit: cover;
    margin:0 5px;
    padding:0px;
}
.image-item .clip-no{
    display:none;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left: 0;
    text-align: center;
    padding-top: 28%;
    color:#191919;
    font-size: 14px;
    z-index: 9;

}
.image-item:hover .clip-no{
    display:block;
}
.image-item:hover .item{
    opacity: 0.4;
}
.playimageicon{
    display:none;
    position: absolute;
    left:47%;
    top:47%
}
.image-holder:hover .playimageicon{
    display:block;
}
.video-bottomtools{
    bottom:0;
    height: 54px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.25px solid #70707021;
    opacity: 1;
    width:100%;
    z-index: 999;
    padding-right: 370px !important;
}

.ant-dropdown-menu-title-content{
    display: flex;
}