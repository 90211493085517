/* For Calendar */

.month-lable{
    font-size: 16px;   
    font-weight: 600;
}

.app-calendar .rbc-off-range-bg{
    background-color: #fff;
}
.app-calendar .calender-tool-pre-btn{
    border-radius:7px;
    border-top-right-radius: 0;
   border-bottom-right-radius: 0;
   margin-right: 0!important;
   border-width: 0;
   box-shadow: 0 0 0 1px #eaebed inset;
}
.app-calendar .calender-tool-today-btn{
 border-radius: 0;
 border-width: 0;
 box-shadow: 0 0 0 1px #eaebed inset;
   
}
.app-calendar .calender-tool-next-btn{
    border-radius:7px;
    border-top-left-radius: 0;
   border-bottom-left-radius: 0;
   margin-left: 0!important;
   border-width: 0;
   box-shadow: 0 0 0 1px #eaebed inset;
}
.app-calendar .calender-tool-next-btn:hover,.app-calendar .calender-tool-today-btn:hover,.app-calendar .calender-tool-pre-btn:hover{
    box-shadow: 0 0 0 1px #2ca7f0 inset;
}
.app-calendar .ant-btn.calender-tool-today-btn[disabled]{
    background: #f5f5f549;
    cursor: pointer;
    border-width: 0;
    box-shadow: 0 0 0 1px #eaebed inset;
}
.app-calendar .rbc-month-view{
    box-shadow: 5px 5px 0 0px rgb(223 226 234 / 50%);
    border-radius: 9px;
    border: 1px solid #d9d9d98c;
}
.app-calendar .rbc-header, .app-calendar .rbc-header + .rbc-header, .app-calendar .rbc-month-row + .rbc-month-row, .app-calendar .rbc-day-bg + .rbc-day-bg{
    border:none;
}
.app-calendar .rbc-header{
    padding:5px 0px;
    text-transform: uppercase;
    font-weight: 700;

}
.app-calendar .rbc-today{
    border-radius:10px;
}
.app-calendar .rbc-event.rbc-selected{
    background-color: #fff;
    border:none;
    outline:none;
}
.app-calendar .rbc-event:focus ,.app-calendar .rbc-event.rbc-selected:focus{
    outline:none;
}
.app-calendar .rbc-event,.rbc-overlay .rbc-event{
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 13px -5px #eaebed;
    border: 1px solid #eaebed;
    margin-bottom: 12px;
    width: 100%;
    margin-bottom: 4px;
    padding: 6px;
}
.calendar-event-content{
    color: #333;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
    height: 30px;
    overflow: hidden;
}
.calendar-event-content .calendar-event-image{
    width: 100%;
   height: 115px;
   background-position: center center;
   background-repeat: no-repeat;
   overflow: hidden;
   border-radius: 5px;
   margin-bottom: 10px;
   height: 30px;
   width: 30px;
   margin-bottom: 0;
   flex: none;
   margin-right: 10px;
   position: relative;
   background: #f9f9f9;
   border-radius: 2px;
}
.calendar-event-image .sicon{
    float: left;
    width: 22px;
    height: 22px;
    border-radius: 3px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 99;
}
.calendar-event-image.single .sicon{
    
    width: 22px;
    height: 22px;
    border-radius: 3px;
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 99;
}
.calendar-event-image.multipule{
   display: grid;
   grid-template-columns: repeat(2,1fr);
   grid-auto-rows: 10px;
   grid-gap: 2px;
   background: #f9f9f9;
   border-radius: 2px;
}
.calendar-event-image.multipule .sicon{
    border-radius: 2px;
   width: 10px;
   height: 10px;
   position: initial;
   float: initial;
}
.calendar-event-image img{
    object-fit: cover;
   object-position: center;
   height: 100%;
   width: 30px;
}
.calendar-event-content .calendar-event-text{
    font-size: 12px;
   line-height: 15px;
   text-overflow: ellipsis;
    display:-webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
}