.page-container {
    height: calc(100vh - 54px);
    position: relative;
}
.video-header{
    margin-top: 54px !important;
}
.video-creator .content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
}
.video-creator #left-tabs {
    flex: 0 0 70px;
    outline: 1px solid #DDDEE6;
    z-index: 2;
    padding: 0;
    height: 100%;
    background-color: #FAFAFB;
    overflow-x: hidden;
    overflow-y: auto;
}
#left-tabs .ant-tabs-tab{
    text-align: center;
    justify-content: center;
    border: 0 !important;
}
#left-tabs .ant-tabs-tab{
    border-left: 2px solid #fafafa !important;
}
#left-tabs .ant-tabs-tab.ant-tabs-tab-active{
    color: #f6327d !important;
    border-left: 2px solid #f6327d !important;
}
#left-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #f6327d !important;
    text-shadow: 0 0 0.25px currentColor;
}
#left-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f6327d !important;
    text-shadow: 0 0 0.25px currentColor;
}
#left-tabs .ant-tabs-nav{
    width: 100% !important;
}
.video-creator #left-pane {
    flex: 0 0 500px;
    padding: 0;
    border-right: 1px solid #DDDEE6;
    z-index: 1;
    background-color: #fff;
    height: 100% !important;
    overflow: hidden;
}
#left-pane .pane-container{
    display: block !important;
}
#left-pane .pane-container.invisible{
    display: none !important;
}
#left-pane .pane-container.active{
    display: block !important;
}
.story-article .article-import-header {
    height: 70px;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #DDDEE6;
}
.story-article .story-editor {
    /* overflow-y: auto; */
    height: calc(100% - 70px);
}
.images-gallery-list{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-auto-rows: 150px;
    grid-gap: 20px;
}
.images-gallery-list.col-four{
    grid-template-columns: repeat(4,1fr);
}
.images-gallery-list .item{
    padding: 0;
    border-radius: 6px;
    position: relative;
    background-color: rgba(25,45,65,0.5);
}
.images-gallery-list .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 6px;
}
.images-gallery-list .portrait-background {
    -webkit-filter: blur(8px) brightness(50%);
    -moz-filter: blur(8px) brightness(50%);
    -o-filter: blur(8px) brightness(50%);
    -ms-filter: blur(8px) brightness(50%);
    filter: blur(8px) brightness(50%);
    background-size: cover;
}

.images-gallery-list .item .square-img{
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}
.images-gallery-list .portrait-background+.square-img {
    background-size: auto 100%;
}
.images-gallery-list.postnew .item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}
.images-gallery-list .portrait-background+video.square-img {
    left: 33%;
    width: auto;
}
.images-gallery-list .item .image-action{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(44,167,240,.08);
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.FormatSelectionOption{
    width: 100%;
    display: flex;
    padding: .3em;
    background: #e1e1e1;
    border-radius: 3px;
    height: 300px;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}
.FormatSelectionLabel{
    font-family: "Muli", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 1.1em;
    color: #333;
}
.FormatViewOption{
    width: 60%;
    padding-bottom: 60%;
    border-radius: 2px;
    background: #fff;
    position: relative;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.19), 0 1px 3px rgba(0, 0, 0, 0.23);
}
.LandscapeSample{
    width: 90%;
    padding-bottom: 56.25%;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    left: 5%;
    top: 20%;
    background-image: url("https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/campaign-14/Cades-Cove-in-Fall-1024x683.jpg");
}

.SquareSample{
    left: 13%;
    top: 13%;
    width: 74%;
    height: 74%;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/campaign-14/Cades-Cove-in-Fall-1024x683.jpg");
}
.VerticleSample{
    left: 29%;
    top: 13%;
    width: 43%;
    height: 74%;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/campaign-14/Cades-Cove-in-Fall-1024x683.jpg");
}
/* .VerticleSample {
    left: 4%;
    width: 92%;
    background-image: url("https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/campaign-14/Cades-Cove-in-Fall-1024x683.jpg");
} */
.ShowSelection{
    transition: transform 0.15s;
    transform: scale(1);
}
.FormatSelectionOption:hover .ShowSelection{
    transform: scale(1.05);
}
.FormatInfo{
    font-family: "Muli", sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: .8em;
    color: #333;
}
.TemplatesSelection{
    min-height: 180px;
    margin-bottom: 1em;
}
.vetitempl{
    min-height: 220px;
}
.TemplatesSelection.vetitempl .imageSrc{
    width: 140px;
    height: 140px;
}
.ShowSelection{
    transition: transform 0.15s;
    transform: scale(1);
}
.TemplatesSelection:hover .ShowSelection{
    transform: scale(1.05);
}
.FormatViewOption.active{
    border: 1px solid palevioletred !important;
}

.name, .audio-asset.name {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
    white-space: nowrap;
    font-size: 15px;
    max-width: 86%;
}
.playselected:hover .selectedone{
    display: none !important;
    
}
.playselected:hover .selectedplay{
    display: block !important;
}
.upload-modal .ant-upload.ant-upload-select{
    display: block !important;
    height: 100%;
}